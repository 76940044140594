import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import api from "../services/api";
import {AxiosResponse} from "axios";
import {RootState} from "../store";

export const fetchCourseDetails = createAsyncThunk('course/fetchCourseDetails', async (courseId: number) => {
    const response = await api.get(`/courses/${courseId}/details`);
    return response.data;
});

// Thunk to update course details
export const saveCourseDetails = createAsyncThunk('courseDetails/updateCourseDetails', async (details: {
    id: number, title: string, description: string
}, thunkAPI) => {
    try {
        await api.put(`/courses/${details.id}/save`, details);
    } catch (error: any) {
        // Handle error: return a rejected value with the error message
        return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update course details');
    }
});

export const addGroup = createAsyncThunk("courseDetails/addGroup", async (details: { name: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const courseId = state.courseDetails.details?.id;
    try {
        const resp = await api.post(`/courses/${courseId}/groups/add`, details);
        return resp.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update course details');
    }
});

export const saveGroup = createAsyncThunk("courseDetails/saveGroup", async (details: {
    groupId: number,
    name: string
}, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const courseId = state.courseDetails.details?.id;
    try {
        const resp = await api.post(`/courses/${courseId}/groups/save`, details);
        return resp.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update course details');
    }
});

// Thunk to update course details
export const saveModuleDetails = createAsyncThunk('courseDetails/saveModule', async (details: {
    courseId: number, id: number, title: string, description: string
}, thunkAPI) => {
    try {
        await api.put(`/courses/${details.courseId}/modules/save`, details);
    } catch (error: any) {
        // Handle error: return a rejected value with the error message
        return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update module details');
    }
});

export const addModule = createAsyncThunk<Module, { courseId: number, title: string, description: string }, {
    rejectValue: string
}>('courseDetails/addModule', async (details, thunkAPI) => {
    try {
        const response: AxiosResponse<Module> = await api.put(`/courses/${details.courseId}/modules/add`, details);
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add module';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const deleteModule = createAsyncThunk('courseDetails/deleteModule', async (details: {
    id: number, courseId: number
}, thunkAPI) => {
    try {
        await api.delete(`/courses/${details.courseId}/modules/${details.id}`);
        return details.id;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add module';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const publishModule = createAsyncThunk('courseDetails/publishModule', async (details: {
    id: number, courseId: number, published: boolean
}, thunkAPI) => {
    try {
        const resp: AxiosResponse<Module> = await api.post(`/courses/${details.courseId}/modules/publish`, {
            moduleId: details.id,
            published: !details.published,
        });
        return resp.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add module';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const publishAssignment = createAsyncThunk('courseDetails/publishAssignment', async (details: {
    id: number, courseId: number, published: boolean
}, thunkAPI) => {
    try {
        const resp: AxiosResponse<Assignment> = await api.post(`/courses/${details.courseId}/assignments/publish`, {
            assignmentId: details.id,
            published: !details.published,
        });
        return resp.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add assignment';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const addAssignment = createAsyncThunk('courseDetails/addAssignment', async (newAssignment: {
    courseId: number, moduleId: number, title: string
}) => {
    const response = await api.post(`/courses/${newAssignment.courseId}/assignments/add`, newAssignment);
    return response.data;
});

export interface Assignment {
    id: number,
    courseId: number,
    moduleId: number,
    title: string,
    published: boolean;
}

export interface Module {
    id: number,
    courseId: number,
    title: string,
    description: string,
    assignments: Assignment[],
    published: boolean,
}

export interface Member {
    id: number;
    name: string;
}

export interface Group {
    id: number;
    name: string;
    invite: string;
    members: Member[];
}

export interface CourseDetails {
    id: number;
    modules: Module[];
    groups: Group[];
    title: string;
    description: string | null;
}

interface CourseDetailsState {
    details: CourseDetails | null;
    loading: boolean;
    error: string | null;
    modalWindow: null | number | "course" | "new";
}

const initialState: CourseDetailsState = {
    details: null, loading: false, error: null, modalWindow: null,
};

const courseDetailsSlice = createSlice({
    name: 'courseDetails', initialState, reducers: {
        clearCourseDetails(state) {
            state.details = null;
        }, updateCourseDetails(state, action: PayloadAction<{ title: string; description: string }>) {
            if (!state.details) return;
            state.details.title = action.payload.title;
            state.details.description = action.payload.description;
        }, updateModuleDetails(state, action: PayloadAction<{ id: number, title: string; description: string }>) {
            const module = state.details?.modules.find(m => m.id === action.payload.id);
            if (module) {
                module.title = action.payload.title;
                module.description = action.payload.description;
            }
            // ToDo: report error
        }, setCourseModalOpen(state) {
            if (state.modalWindow === null) {
                state.modalWindow = "course";
            }
        }, setModuleModalOpen(state, action: PayloadAction<number>) {
            if (state.modalWindow === null) {
                state.modalWindow = action.payload;
            }
        }, setNewModuleModalOpen(state) {
            if (state.modalWindow === null) {
                state.modalWindow = "new";
            }
        }, setModalClosed(state) {
            state.modalWindow = null;
        }, setLoading(state) {
            state.loading = true;
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(addGroup.fulfilled, (state, action) => {
                state.details?.groups.push(action.payload);
            })
            .addCase(fetchCourseDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCourseDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.details = action.payload;
            })
            .addCase(fetchCourseDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch courses';
            })
            .addCase(addModule.fulfilled, (state, action) => {
                if (!state.details) return;
                state.details.modules.push(action.payload);
            })
            .addCase(addAssignment.fulfilled, (state, action) => {
                if (!state.details) return;
                for (const module of state.details.modules) {
                    if (module.id === action.payload.moduleId) {
                        module.assignments.push(action.payload);
                        return;
                    }
                }
            })
            .addCase(deleteModule.fulfilled, (state, action) => {
                if (!state.details) return;
                state.details.modules = state.details.modules.filter(module => module.id !== action.payload);
            })
            .addCase(publishAssignment.fulfilled, (state, action) => {
                const assignment =
                    state.details?.modules.find(module => module.id === action.payload.moduleId)
                    ?.assignments.find(assignment => assignment.id === action.payload.id);
                if (assignment) assignment.published = action.payload.published;
            })
            .addCase(publishModule.fulfilled, (state, action) => {
                const module = state.details?.modules.find(module => module.id === action.payload.id);
                if (module) module.published = action.payload.published;
            });
    },
});

export const {
    clearCourseDetails,
    updateCourseDetails,
    updateModuleDetails,
    setCourseModalOpen,
    setModalClosed,
    setModuleModalOpen,
    setLoading,
    setNewModuleModalOpen,
} = courseDetailsSlice.actions;

export default courseDetailsSlice.reducer;
