import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {deleteOption, SelectOption, updateOption} from "../../../slices/assignmentSlice";

import {ReactComponent as CancelIcon} from "../../../svg/cancel.svg";
import {ReactComponent as CheckBoxChecked} from "../../../svg/checkbox-checked.svg";
import {ReactComponent as CheckBoxUnchecked} from "../../../svg/checkbox-unchecked.svg";
import {ReactComponent as CheckBoxCrossed} from "../../../svg/checkbox-crossed.svg";
import {ReactComponent as ApplyIcon} from "../../../svg/save.svg";
import {ReactComponent as EditIcon} from "../../../svg/pencil.svg";
import MathJax from "../../../components/MathJax";
import AutoResizeTextArea from "./AutoResizeTextArea";
import {t} from "i18next";


interface OptionProps {
    index: number;
    optionId: number;
}

const OptionCheckBox = (props: { checked?: SelectOption }) => {
    if (props.checked === 1) return (<CheckBoxChecked/>);
    if (props.checked === 0) return (<CheckBoxCrossed/>);
    return <CheckBoxUnchecked/>;
}

const OptionEditor: React.FC<OptionProps> = ({optionId, index}) => {
    const dispatch = useDispatch<AppDispatch>();

    const option = useSelector((state: RootState) => state.assignment.options.find(o => o.id === optionId));
    const text = option?.value || "";
    const checked = option?.checked;

    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    }

    useEffect(() => {
        setIsEditing(false);
    }, []);

    if (!option) return null;

    const handleCheckboxClick = () => {
        let newValue: SelectOption;
        if (checked === 0) newValue = null; else if (checked === 1) newValue = 0; else newValue = 1;
        dispatch(updateOption({optionId, checked: newValue}));
    }

    const handleDeleteOption = () => {
        dispatch(deleteOption(optionId));
    }

    const callback = (s: string) => dispatch(updateOption({optionId, value: s}));

    return <div className="option-item">
        <button onClick={handleEditClick} className={"button"}>{isEditing ? <ApplyIcon/> : <EditIcon/>}</button>
        {isEditing || <button onClick={handleDeleteOption} className={"button delete-button"}><CancelIcon/></button>}
        {isEditing && <AutoResizeTextArea value={text} placeholder={t("answer_option")} callback={callback}/>}
        {isEditing || <div className={"option-preview"}>
            <button className={"button"} onClick={handleCheckboxClick}><OptionCheckBox checked={checked}/></button>
            <span className="option-index">{index + 1}.</span>
            <MathJax><span className="option-text">{text}</span></MathJax>
        </div>}
    </div>;
};

export default OptionEditor;