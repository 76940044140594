import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import api from '../services/api';
import {AxiosResponse} from 'axios';
import {RootState} from "../store";

export type SelectOption = null | 0 | 1;

export interface AnswerOption {
    id: number;
    value: string;
    checked: SelectOption;
    variantId: number;
}

export interface QuestionVariant {
    id: number;
    text: string;
    options: AnswerOption[];
    expected?: string;
    questionId?: number;
}

export interface Question {
    id: number;
    type: string;
    variants: QuestionVariant[];
}

export interface Assignment {
    id: number;
    courseId: number; // for a return link
    title: string;
    published: number;
    questions: Question[];
}

interface AssignmentState {
    details: Assignment;
    options: AnswerOption[];
    questions: Question[];
    variants: QuestionVariant[];
    loading: boolean;
    error: string | null;
}

const InitialAssignment: () => Assignment = () => {
    return {id: 0, courseId: 0, title: "", published: 0, questions: [],}
};

const initialState: AssignmentState = {
    details: InitialAssignment(), loading: false, error: null, options: [], questions: [], variants: [],
};

// Fetch assignment details
export const fetchAssignment = createAsyncThunk('assignment/fetchAssignment', async (details: {
    courseId: number, assignmentId: number
}) => {
    const response = await api.get(`/courses/${details.courseId}/assignments/${details.assignmentId}`);
    return response.data;
});

// Save assignment details
export const saveAssignment = createAsyncThunk('assignment/saveAssignment', async (_, thunkAPI) => {
    const state = (thunkAPI.getState() as RootState).assignment;
    const assignment: Assignment = JSON.parse(JSON.stringify(state.details));

    for (const question of state.questions) {
        const q: Question = JSON.parse(JSON.stringify(question));
        q.variants = state.variants.filter(v => v.questionId === q.id).map(v => JSON.parse(JSON.stringify(v)));
        for (const v of q.variants) {
            v.options = state.options.filter(o => o.variantId === v.id).map(o => JSON.parse(JSON.stringify(o)));
        }
        assignment.questions.push(q);
    }

    try {
        const response: AxiosResponse<Assignment> = await api.post(`/courses/${assignment.courseId}/assignments/${assignment.id}/save`, assignment);
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to save assignment';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const deleteQuestion = createAsyncThunk('assignment/deleteQuestion', async (questionId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const courseId = state.assignment.details?.courseId;
    if (!courseId) return thunkAPI.rejectWithValue("Wrong course id");
    try {
        await api.delete(`/courses/${courseId}/question/${questionId}`);
        return questionId;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add module';
        return thunkAPI.rejectWithValue(errorMessage);
    }
})

export const addQuestion = createAsyncThunk('assignment/newQuestion', async (args: {
    courseId: number, assignmentId: number
}, thunkAPI) => {
    const {courseId, assignmentId} = args;
    try {
        const response: AxiosResponse<Question> = await api.put(`/courses/${courseId}/question/add`, {assignmentId});
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add module';
        return thunkAPI.rejectWithValue(errorMessage);
    }
})

export const addVariant = createAsyncThunk('assignment/addVariant', async (questionId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const courseId = state.assignment.details?.courseId;
    if (!courseId) return thunkAPI.rejectWithValue("Wrong course id");
    try {
        const response = await api.post(`/courses/${courseId}/questions/${questionId}/add_variant`);
        return {variant: response.data, questionId};
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add variant';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const deleteVariant = createAsyncThunk('assignment/deleteVariant', async (variantId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const courseId = state.assignment.details?.courseId;
    if (!courseId) return thunkAPI.rejectWithValue("Wrong course id");
    try {
        await api.delete(`/courses/${courseId}/variants/${variantId}`);
        return variantId;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to delete variant';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const addOption = createAsyncThunk('assignment/addOption', async (variantId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const courseId = state.assignment.details?.courseId;
    if (!courseId) return thunkAPI.rejectWithValue("Wrong course id");
    try {
        const response = await api.post(`/courses/${courseId}/variants/${variantId}/add_option`);
        return {variantId, option: response.data};
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to add option';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const deleteOption = createAsyncThunk('assignment/deleteOption', async (optionId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const courseId = state.assignment.details?.courseId;
    if (!courseId) return thunkAPI.rejectWithValue("Wrong course id");
    try {
        await api.delete(`/courses/${courseId}/options/${optionId}`);
        return optionId;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to delete option';
        return thunkAPI.rejectWithValue(errorMessage);
    }
});


function assignVariants(state: AssignmentState, q: Question) {
    state.questions.push(q);
    for (const v of q.variants) {
        v.questionId = q.id;
        state.variants.push(v);
        for (const o of v.options) {
            o.variantId = v.id;
            state.options.push(o);
        }
    }
}

function applyAssignment(state: AssignmentState, assignment: Assignment) {
    const {id, title, courseId} = assignment;
    state.details = InitialAssignment();
    state.details.title = title;
    state.details.id = id;
    state.details.courseId = courseId;
    state.questions = [];
    state.variants = [];
    state.options = [];

    assignment.questions.map((q: Question) => assignVariants(state, q));
}

const assignmentSlice = createSlice({
    name: 'assignment', initialState, reducers: {
        updateAssignment(state, action: PayloadAction<{ id: number; title: string }>) {
            // ToDo: extra safety only clutters
            if (state.details && state.details.id === action.payload.id) {
                state.details.title = action.payload.title;
            }
        },
        clearAssignment(state) {
            state.details = InitialAssignment();
        },
        updateOption(state, action: PayloadAction<{
            optionId: number,
            value?: string,
            checked?: 1 | 0 | null
        }>) {
            const {optionId, value, checked} = action.payload;
            const option = state.options.find(o => o.id === optionId);
            if (!option) return;

            if (value !== undefined) option.value = value;
            if (checked !== undefined) option.checked = checked;
        },
        updateQuestion(state, action: PayloadAction<{
            questionId: number, type?: string, variantId?: number, text?: string, optionIndex?: number, value?: string
        }>) {
            const {questionId, type} = action.payload;
            const question = state.questions.find(q => q.id === questionId);
            if (!question) return;

            if (type) question.type = type;
        },
        updateVariant(state, action: PayloadAction<{
            variantId: number, text?: string, expected?: string
        }>) {
            const {variantId, text, expected} = action.payload;
            const variant = state.variants.find(v => v.id === variantId);

            if (!variant) return;
            if (text !== undefined) variant.text = text;
            if (expected !== undefined) variant.expected = expected;
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(addVariant.fulfilled, (state, action) => {
                const {questionId, variant} = action.payload;
                variant.questionId = questionId;
                state.variants.push(variant);
            })
            .addCase(addVariant.rejected, (state, action) => {
                state.error = action.payload as string;
            })
            .addCase(deleteVariant.fulfilled, (state, action) => {
                state.variants = state.variants.filter(v => v.id !== action.payload);
            })
            .addCase(addOption.fulfilled, (state, action) => {
                const {variantId, option} = action.payload;
                option.variantId = variantId;
                state.options.push(option);
            })
            .addCase(deleteOption.fulfilled, (state, action) => {
                state.options = state.options.filter(o => o.id !== action.payload);
            })
            .addCase(deleteQuestion.fulfilled, (state, action) => {
                state.questions = state.questions.filter(q => q.id !== action.payload);
            })
            .addCase(fetchAssignment.fulfilled, (state, action) => {
                applyAssignment(state, action.payload);
                state.loading = false;
            })
            .addCase(fetchAssignment.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAssignment.rejected, (state, action) => {
                state.error = action.error.message || 'Failed to fetch assignment';
                state.loading = false;
            })
            .addCase(addQuestion.fulfilled, (state, action) => {
                const q: Question = action.payload;
                assignVariants(state, q);
            })
            .addCase(saveAssignment.fulfilled, (state, action) => {
                applyAssignment(state, action.payload);
            });
    },
});

export const {
    clearAssignment, updateAssignment, updateOption, updateQuestion, updateVariant
} = assignmentSlice.actions;

export default assignmentSlice.reducer;
