import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import coursesReducer from './slices/coursesSlice';
import enrolmentReducer from './slices/enrolmentSlice';
import errorReducer from './slices/errorSlice';
import courseDetailsReducer from "./slices/courseDetailsSlice";
import editableFieldsReducer from "./components/editableField/editableFieldSlice";
import assignmentReducer from "./slices/assignmentSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        courses: coursesReducer,
        enrolment: enrolmentReducer,
        error: errorReducer,
        courseDetails: courseDetailsReducer,
        editableFields: editableFieldsReducer,
        assignment: assignmentReducer,
    },
});

// Define RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
