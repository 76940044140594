import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loginSuccess, setReturnUrl} from './authSlice';
import {AppDispatch, RootState} from '../../store';
import './LoginForm.css';
import {useNavigate} from "react-router-dom";
import api from "../../services/api";

const LoginForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>(''); // Only for sign-up
    const [firstName, setFirstName] = useState<string>(''); // First name for sign-up
    const [lastName, setLastName] = useState<string>(''); // Last name for sign-up
    const [error, setError] = useState<string | null>(null);
    const [isSignUp, setIsSignUp] = useState<boolean>(false); // State to toggle between login and sign-up

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const returnUrl = useSelector((state: RootState) => state.auth.returnUrl);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isSignUp) {
            // Sign-up logic
            if (password !== confirmPassword) {
                setError('Passwords do not match');
                return;
            }
            try {
                api.post('/auth/register', {
                    email,
                    password,
                    firstName,
                    lastName,
                }).then(() =>
                    navigate("/login"));
            } catch (err) {
                setError('Registration failed');
            }
        } else {
            try {
                const response = await api.post('/auth/login', {email, password});
                const token = response.data.token;
                const refreshToken = response.data.refreshToken;
                const displayName = response.data.displayName;
                const userDetails: any = {}; // ToDo:
                const isTeacher = !!response.data.isTeacher;
                userDetails.isTeacher = isTeacher;
                userDetails.displayName = response.data.displayName;

                localStorage.setItem('jwtToken', token);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('userDetails', JSON.stringify(userDetails));

                dispatch(loginSuccess({token, displayName, isTeacher, refreshToken}));
                dispatch(setReturnUrl(null));
                navigate(returnUrl || "/");
            } catch (err) {
                setError('Invalid credentials');
            }
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>{isSignUp ? 'Sign Up' : 'Login'}</h2>
                <form onSubmit={handleSubmit}>
                    {isSignUp && (
                        <>
                            <div className="form-group">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                />
                            </div>
                        </>
                    )}
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {isSignUp && (
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    {error && <p className="error">{error}</p>}
                    <button type="submit" className="login-button">
                        {isSignUp ? 'Create Account' : 'Login'}
                    </button>
                </form>
                <p className="toggle-link">
                    {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
                    <span className="toggle-link" onClick={() => setIsSignUp(!isSignUp)}>
                        {isSignUp ? 'Login here' : 'Create one'}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default LoginForm;
