import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { removeError } from '../slices/errorSlice';
import {ReactComponent as CrossIcon } from "../svg/cancel.svg";

const ErrorDisplay: React.FC = () => {
    const dispatch = useDispatch();
    const errors = useSelector((state: RootState) => state.error.errors);

    const handleDismiss = (index: number) => {
        dispatch(removeError(index));
    };

    return (
        <div className="error-display">
            {errors.map((error, index) => (
                <div key={index} className="error-item">
                    <p>{error}</p>
                    <button className="dismiss-btn" onClick={() => handleDismiss(index)}>
                        <CrossIcon/>
                    </button>
                </div>
            ))}
        </div>
    );
};

export default ErrorDisplay;
