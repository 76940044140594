import React, {ReactElement, useEffect} from "react";


const MathJax: React.FC<{children: ReactElement<any, any> | null}> = (props) => {
    useEffect(()=>{
        if(window?.MathJax && window.MathJax.typeset) {
            window.MathJax.typeset();
        }
    },[props])

    return (<>{props.children}</>);
}

export default MathJax;
