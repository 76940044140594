import axios from 'axios';
import eventEmitter from "../eventEmitter";

// Create an Axios instance
const api = axios.create({
    baseURL: '/api/v1',
    headers: {"Content-Type": "application/json"}
});

let isRefreshing = false; // Flag to prevent multiple refresh calls
let failedQueue: any[] = []; // Queue for failed requests while refreshing

// Helper function to process the queue of failed requests
const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });
    failedQueue = [];
};

// Request interceptor to add jwtToken to the headers
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwtToken'); // Retrieve token from local storage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // Ensure token is added as string
        }
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle expired tokens and refresh flow
api.interceptors.response.use(
    (response) => response, // On successful response, just return it
    async (error) => {
        const originalRequest = error.config;

        // If the error response is 401 (unauthorized) and the token was not already refreshed
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            localStorage.removeItem('jwtToken'); // expired, no need to keep using it
            if (isRefreshing) {
                // If a refresh is already in progress, add the failed request to the queue
                return new Promise<string | null>(function (resolve, reject) {
                    failedQueue.push({resolve, reject});
                })
                    .then((token: string | null) => {
                        if (token) {
                            originalRequest.headers['Authorization'] = 'Bearer ' + token; // Retry with new token
                            return api(originalRequest);
                        }
                        // If no token is available, reject the request
                        return Promise.reject(error);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const oldRefreshToken = localStorage.getItem('refreshToken');
            if (!oldRefreshToken) {
                // If there's no refresh token, log the user out or redirect them to login
                return Promise.reject(error);
            }

            try {
                // Make the refresh token API call
                const response = await api.post('/auth/refresh', {token: oldRefreshToken});

                // Update tokens in local storage
                const {token, refreshToken} = response.data;
                localStorage.setItem('jwtToken', token);
                localStorage.setItem('refreshToken', refreshToken);
                eventEmitter.emit("refresh");

                // Retry the failed requests with the new token
                processQueue(null, token);

                // Retry the original request with the new token
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return api(originalRequest);
            } catch (refreshError) {
                // If refreshing the token fails, process failed requests and reject them
                processQueue(refreshError, null);
                eventEmitter.emit("logout");
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

export default api;
