// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files (you can expand this with more languages)
const resources = {
    en: {
        translation: {
            greeting: "Hello, {{name}}!",
            loading: "Loading...",
            logout: "Logout",
            modules: "Modules",
            no_courses: "No courses available",
            registered_groups: "Groups",
            switchToTeacherMode: "Switch to Teacher Mode",
            switchToStudentMode: "Switch to Student Mode",
            your_courses: "Your Courses",
        }
    },
    ru: {
        translation: {
            add_assignment: "Добавить тест",
            add_group: "Добавить группу",
            add_module: "Добавить модуль",
            add_option: "Добавить вариант ответа",
            add_question: "Добавить вопрос",
            answer_option: "Вариант ответа",
            apply: "Применить",
            assignment_title: "Название теста",
            assignments: "Тесты",
            back_to_course: "Назад к курсу",
            delete_question: "Удалить вопрос",
            delete_variant: "Удалить вариант вопроса",
            edit: "Редактировать",
            expected_text: "Ожидаемый ответ",
            file_upload: "Загрузка файла",
            free_answer: "Открытый ответ",
            greeting: "Здравствуйте, {{name}}!",
            hide: "Скрыть",
            loading: "Загрузка...",
            logout: "Выйти",
            modules: "Модули",
            multiple_choice: "Множественный выбор",
            no_courses: "Нет доступных курсов",
            no_members: "Нет участников",
            publish: "Опубликовать",
            question_text: "Текст вопроса",
            questions: "Вопросы",
            registered_groups: "Зарегистрированные группы",
            remove: "Удалить",
            save_assignment: "Сохранить тест",
            single_choice: "Единственный выбор",
            switchToTeacherMode: "Режим учителя",
            switchToStudentMode: "Режим студента",
            untitled: "Без названия",
            variants: "Варианты",
            your_courses: "Ваши курсы",
        }
    }
    // Add more languages here...
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'ru', // Default language
        fallbackLng: 'en', // Fallback language if translation is missing
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
    });

export default i18n;
