import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from "../services/api";

interface Course {
    id: number;
    title: string;
    desc: string;
}

interface CourseResponse {
    courses: Course[];
}

interface CoursesState {
    courses: Course[];
    loading: boolean;
    error: string | null;
}

const initialState: CoursesState = {
    courses: [],
    loading: false,
    error: null,
};

export const fetchCourses = createAsyncThunk<CourseResponse, string>(
    'courses/fetchCourses',
    async () => {
        const response = await api.get('/courses/list_enrolled');
        return response.data;
    }
);

const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourses.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload.courses;
            })
            .addCase(fetchCourses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch courses';
            });
    },
});

export default coursesSlice.reducer;
