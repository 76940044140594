import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logout, toggleMode} from '../../features/auth/authSlice';
import {AppDispatch, RootState} from '../../store';
import {useNavigate} from 'react-router-dom';
import '../../styles/header.css';
import i18n, {t} from "i18next";

const Header: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const handleLogout = () => {
        dispatch(logout()); // Dispatch the logout action
        navigate('/login'); // Redirect to the login page
    };

    const {isTeacher, teacherMode, displayName} = useSelector((state: any) => state.auth);

    const handleModeSwitch = () => {
        dispatch(toggleMode());
    };

    const ToggleModeButton = () =>
        (<button onClick={handleModeSwitch} className="button mode-button">
            {teacherMode ? t('switchToStudentMode') : t('switchToTeacherMode')}
        </button>);

    const LogoutButton = () => (<button className="button logout-button" onClick={handleLogout}>{t('logout')}</button>);

    const switchLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    return (isAuthenticated ? (<header className="header">
        <div className="header-content">
            <span>
                <a className={"home"} href={"/"}>OpenMath</a>
                {displayName && <span>{t('greeting', {name: displayName})}</span>}
            </span>
            <span>
                <select onChange={(e) => switchLanguage(e.target.value)} defaultValue={i18n.language}>
                    <option value="en">English</option>
                    <option value="ru">Русский</option>
                </select>
                {isTeacher && <ToggleModeButton/>}
                {isAuthenticated && <LogoutButton/>}
                {/* Language Switcher */}
                    </span>
        </div>
    </header>) : null);
};

export default Header;
