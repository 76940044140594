import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import api from "../../../services/api";
import {t} from "i18next";
import MathJax from "../../../components/MathJax";
import "../../../styles/courseList.css"

interface Course {
    id: number;
    title: string;
    desc: string;
}

const CourseList: React.FC = () => {
    const [courses, setCourses] = useState<Course[]>([]);

    useEffect(() => {
        api.get('/courses/list_owned')
            .then((response) => {
                setCourses(response.data.courses);
            })
            .catch((error) => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    const CourseBox = (course: Course) => (<div key={course.id} className={"course-box"}>
        <Link to={`/course/${course.id}`} className={"course-box-title"}>
            <MathJax><h3>{course.title}</h3></MathJax>
        </Link>
        {course.desc.split('\n\n').map((s) => (<p>{s}</p>))}
    </div>)

    return (
        <div style={{padding: '20px'}}>
            <h1>{t("your_courses")}</h1>
            {courses.length ? courses.map(CourseBox) : (<p>{t('no_courses')}.</p>)}
        </div>
    );
};

export default CourseList;
