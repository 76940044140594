import { Routes, Route } from 'react-router-dom';
// import StudentDashboard from './pages/StudentDashboard';
import CourseList from './pages/CourseList';
import CourseDetails from './pages/CourseDetails';
import React from "react";

const StudentRoutes: React.FC = () => {
    return (
        <Routes>
            {/*<Route path="/" element={<StudentDashboard />} />*/}
            <Route path="/" element={<CourseList />} />
            <Route path="/courses" element={<CourseList />} />
            <Route path="/course/:id" element={<CourseDetails />} />
            {/* Add more student-specific routes here */}
        </Routes>
    );
};

export default StudentRoutes;
