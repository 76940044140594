import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {t} from "i18next";

import {
    addAssignment,
    addGroup,
    addModule,
    Assignment,
    clearCourseDetails,
    deleteModule,
    fetchCourseDetails,
    Group,
    Module,
    publishAssignment,
    publishModule,
    saveCourseDetails,
    saveModuleDetails,
    setCourseModalOpen,
    setLoading,
    setModalClosed,
    setModuleModalOpen,
    setNewModuleModalOpen,
    updateCourseDetails,
    updateModuleDetails
} from '../../../slices/courseDetailsSlice';
import {AppDispatch, RootState} from '../../../store';
import MathJax from "../../../components/MathJax";
import ModalForm from "../components/ModalForm";

import '../../../styles/courseDetails.css';

import {ReactComponent as EditIcon} from "../../../svg/pencil.svg"; // Edit (Pencil) icon for opening the modal
import {ReactComponent as ClipboardIcon} from "../../../svg/clipboard.svg";
import {ReactComponent as RightArrow} from "../../../svg/right-arrow.svg";
import {ReactComponent as DownArrow} from "../../../svg/down-arrow.svg";

const CourseModalForm: React.FC = () => {
    const course = useSelector((state: RootState) => state.courseDetails.details);
    const [title, setTitle] = useState(course?.title || "");
    const [description, setDescription] = useState(course?.description || "");
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (course) {
            setTitle(course.title);
            setDescription(course.description || "");
        }
    }, [course]);

    const save = () => {
        if (course && course.id) {
            const id = course.id;
            dispatch(updateCourseDetails({title, description}));
            dispatch(saveCourseDetails({id, title, description}));
        }
        dispatch(setModalClosed());
    }

    const preview = () => {
        dispatch(updateCourseDetails({title, description}));
    }

    const cancel = () => {
        dispatch(setModalClosed());
    }

    return ModalForm({save, cancel, preview, title, description, setTitle, setDescription});
}

interface ModuleModalProps {
    id: number,
    courseId: number,
    title: string,
    description: string
}

const ModuleModalForm: React.FC<ModuleModalProps> = ({id, courseId, title, description}) => {
    const [localTitle, setLocalTitle] = useState(title);
    const [localDescription, setLocalDescription] = useState(description);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        setLocalTitle(title);
        setLocalDescription(description || "");
    }, [title, description]);

    const save = () => {
        if (id) {
            dispatch(updateModuleDetails({id, title: localTitle, description: localDescription}));
            dispatch(saveModuleDetails({courseId, id, title: localTitle, description: localDescription}));
        } else {
            dispatch(addModule({courseId, title: localTitle, description: localDescription}));
        }

        dispatch(setModalClosed());
    }

    const preview = () => {
        dispatch(updateModuleDetails({id, title: localTitle, description: localDescription}));
    }
    // ToDo: check if preview-close has display side effects

    const cancel = () => {
        dispatch(setModalClosed());
    }

    return ModalForm({save, cancel, preview, title: localTitle, description: localDescription, setTitle: setLocalTitle, setDescription: setLocalDescription});
}

const AssignmentDisplay = (assignment: Assignment) => {
    const dispatch = useDispatch<AppDispatch>();
    const course = useSelector((state: RootState) => state.courseDetails.details);

    return course ? <div className={"assignment-title"} key={assignment.id}><a
        href={`/course/${course?.id}/assignment/${assignment.id}`}>
        {assignment.title}
    </a>
        <button className={"button"} onClick={() => dispatch(publishAssignment({
            id: assignment.id,
            courseId: course.id,
            published: assignment.published,
        }))}>
            {assignment.published ? t("hide") : t("publish")}</button>
    </div> : null;
};

const ModuleDisplay: React.FC<Module> = ({id, courseId, title, description, assignments, published}) => {
    const dispatch = useDispatch<AppDispatch>();
    const isModalOpen = useSelector((state: RootState) => state.courseDetails.modalWindow) === id;
    const modalWindow = isModalOpen ? <ModuleModalForm {...{id, title, description, courseId}}/> : null;
    return (
        <div key={module.id} className="module">
            {modalWindow}
            <h3>{title}</h3>
            {description.split("\n\n").map((p, i) => <p key={i}>{p}</p>)}
            <button className="button edit-button" onClick={() => dispatch(setModuleModalOpen(id))}>
                {t("edit")}
            </button>
            <button className={"button"} onClick={() => dispatch(publishModule({id, courseId, published}))}>
                {published ? t("hide") : t("publish")}</button>
            <button className="button delete-button" onClick={() => dispatch(deleteModule({id, courseId}))}>
                {t("remove")}</button>
            <h4>{t('assignments')}</h4>
            {(assignments || []).map(AssignmentDisplay)}
            <button className="button add-button" onClick={() => {
                dispatch(addAssignment({courseId, moduleId: id, title: t("untitled")}))
            }}>
                {t("add_assignment")}
            </button>
        </div>)
}


const CourseDetails: React.FC = () => {
    const {id} = useParams<{ id: string }>(); // Get course ID from route
    const dispatch = useDispatch<AppDispatch>();
    const course = useSelector((state: RootState) => state.courseDetails.details);
    const loading = useSelector((state: RootState) => state.courseDetails.loading);
    const error = useSelector((state: RootState) => state.courseDetails.error);

    const isModalOpen = useSelector((state: RootState) => state.courseDetails.modalWindow);

    useEffect(() => {
        const courseId = parseInt(id || "0");
        if (courseId) {
            if (course && course.id !== courseId)
                dispatch(clearCourseDetails());
            if (!course) {
                dispatch(setLoading())
                dispatch(fetchCourseDetails(courseId)); // Dispatch action to fetch course details
            }
        }
    }, [dispatch, id, course]);

    if (loading) {
        return <div>{t('loading')}</div>;
    } else if (error) {
        return <div>Error: {error}</div>;
    }

    // Function to handle opening the edit modal
    const openEditModal = () => {
        dispatch(setCourseModalOpen());
    };

    const Modules = () => {
        if (!course)
            return null;
        console.log(course.modules);
        return (
            <section className="modules-section">
                <h2>{t('modules')}</h2>
                {course.modules.map(m => <ModuleDisplay key={m.id} {...m}/>)}
                <button className="button add-module" onClick={() => dispatch(setNewModuleModalOpen())}>
                    {t("add_module")}
                </button>
            </section>
        )
    };

    const Group = (group: Group) => {
        const members = group.members;

        const [showMembers, setShowMembers] = useState(false);

        return <div key={group.id}>
            <div className="group">
                <span className="group-name">
                    <button className={"button"} onClick={() => setShowMembers(!showMembers)}>
                        {showMembers ? <DownArrow/> : <RightArrow/>}
                    </button>
                    {group.name}
                </span>
                <div className="group-invite">
                    <span>{group.invite}</span>
                    <button
                        onClick={() => navigator.clipboard.writeText(`${window.location.origin}/enrol?token=${group.invite}`)}
                        className="button copy-button"><ClipboardIcon/></button>
                </div>
            </div>
            {showMembers &&
                <div className={"member-list"}>
                    {members.map((member) => <div key={member.id} className={"member-line"}><span>{member.name}</span>
                    </div>)}
                    {members.length === 0 ? <div>{t("no_members")}</div> : null}
                </div>}
        </div>
    };

    const Groups = () => {
        if (!course)
            return null;
        return (
            <section className="groups-section">
                <h2>{t("registered_groups")}</h2>
                {course.groups.map(Group)}
                <div>
                    <button className={"button"}
                            onClick={() => dispatch(addGroup({name: t("new_group_name")}))}>{t("add_group")}</button>
                </div>
            </section>
        )
    };

    if (!course) {
        return null;
    }

    const newModule = (): Module => {
        return {id: 0, title: "", description: "", courseId: course.id, assignments: [], published: false}
    };

    return (
        <div className="course-details">
            <MathJax>
                <h1>
                    {course.title}
                    <button className="edit-button" onClick={openEditModal}>
                        <EditIcon/>
                    </button>
                </h1>
            </MathJax>
            <p>{course.description}</p>
            <Modules/>
            <Groups/>


            {/* Edit Modal */}
            {isModalOpen === "course" && <CourseModalForm/>}
            {isModalOpen === "new" && <ModuleModalForm {...newModule()}/>}
        </div>
    );
};

export default CourseDetails;
