import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthTokens, logout } from './authSlice';
import api from "../../services/api";
import {RootState} from "../../store";

interface Props {
    children: React.ReactNode;
}

const AuthCheck: React.FC<Props> = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const token =  useSelector((state: RootState) => state.auth.token);

    useEffect(() => {
        const publicPaths = ['/login', '/register']; // Paths that don't require authentication
        if (publicPaths.includes(document.location.pathname)) {
            setLoading(false); // Skip token checking for these routes
            return;
        }

        if (token && isAuthenticated) {
            setLoading(false); // Skip if a token is known
            return;
        }

        const checkAuth = async () => {
            const jwtToken = localStorage.getItem('jwtToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if (jwtToken) {
                // Token is present, proceed as authenticated user
                setLoading(false);
            } else if (refreshToken) {
                // If refreshToken exists, try to get new tokens
                try {
                    const response = await api.post('auth/refresh', { token: refreshToken });
                    const jwtToken = response.data.token;
                    const newRefreshToken = response.data.refreshToken;

                    // Store the new tokens
                    localStorage.setItem('jwtToken', jwtToken);
                    localStorage.setItem('refreshToken', newRefreshToken);

                    // Dispatch the updated tokens to the Redux store
                    dispatch(setAuthTokens({ jwtToken, refreshToken: newRefreshToken }));
                    setLoading(false);
                } catch (error) {
                    console.error('Token refresh failed, redirecting to login:', error);
                    dispatch(logout()); // Clear any previous auth state
                    navigate('/login');
                }
            } else {
                // No tokens present, redirect to login page
                navigate('/login');
            }
        };

        checkAuth().then(() => console.log(""));
    }, [dispatch, isAuthenticated, navigate, token]);

    if (loading) {
        return <div>Loading...</div>; // Show a loading state until tokens are checked
    }

    return <>{children}</>;
};

export default AuthCheck;
