import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    addQuestion,
    clearAssignment,
    fetchAssignment, Question,
    saveAssignment,
    updateAssignment
} from '../../../slices/assignmentSlice';
import {AppDispatch, RootState} from '../../../store';
import {useParams} from "react-router-dom";
import {setLoading} from "../../../slices/courseDetailsSlice";
import {t} from "i18next";
import QuestionEditor from "../components/QuestionEditor";

const AssignmentEditor: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {course, id} = useParams<{ id: string, course: string }>();
    const assignmentId = parseInt(id || "0");
    const courseId = parseInt(course || "0");
    const title = useSelector((state: RootState) => state.assignment.details.title);
    const questionsState = useSelector((state: RootState) => state.assignment.questions);
    const [questions, setQuestions] = useState<Question[]>([]);

    useEffect(() => {
        const assignmentId = parseInt(id || "0");
        const courseId = parseInt(course || "0");
        if (assignmentId && courseId) {
            dispatch(clearAssignment());
            dispatch(setLoading());
            dispatch(fetchAssignment({courseId, assignmentId}));
        } else {
            dispatch(clearAssignment());
        }
    }, [id, course, dispatch]);

    useEffect(() => {
        const q: Question[] = [];
        for (const i in questionsState) q.push(questionsState[i]);
        setQuestions(q);
    }, [questionsState]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateAssignment({id: assignmentId, title: e.target.value}));
    };

    const handleAddQuestion = () => {
        dispatch(addQuestion({courseId, assignmentId}));
    };

    const handleSave = () => {
        dispatch(saveAssignment());
    };

    return (<div className="assignment-editor">
        <p><a href={`/course/${courseId}`}>{t("back_to_course")}</a></p>
        <input value={title} onChange={handleTitleChange} placeholder={t("assignment_title")}/>
        <div className="questions">
            <h3>{t("questions")}</h3>
            {questions.map((q, index) => (
                <QuestionEditor key={`q${q.id}`} {...{index, questionId: q.id, type: q.type}}/>))}
        </div>
        <button className={"button"} onClick={handleAddQuestion}>{t("add_question")}</button>
        <button className={"button"} onClick={handleSave}>{t("save_assignment")}</button>
    </div>);
};

export default AssignmentEditor;
