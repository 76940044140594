import React, {useState} from "react";

type Callback = ((s: string) => void );
interface Props {
    value: string;
    callback?: Callback;
    placeholder: string;
}

const AutoResizeTextArea: React.FC<Props> = ({value, callback, placeholder}) => {
    const [v, setV] = useState<string>(value);
    const [edited, setEdited] = useState(false);

    const resize = (e: HTMLTextAreaElement) => {
        e.style.height = 'auto';  // Reset the height
        e.style.height = (e.scrollHeight) + 'px';  // Set it to the scroll height
    }

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        edited && value !== v && callback && callback(e.target.value);
        setEdited(false);
        e.target.style.height = 'auto';  // Reset the height
    }

    return <textarea
        className={"auto-resize"}
        value={v}
        onFocus={e => resize(e.target)}
        onBlur={handleBlur}
        onChange={(e) => {
            setEdited(true);
            setV(e.target.value);
            resize(e.target);
        }}
        placeholder={placeholder || ""}
    />;
}
export default AutoResizeTextArea;