import React, {useEffect} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {enrolUser} from '../../slices/enrolmentSlice';
import {RootState, AppDispatch} from '../../store';
import {setReturnUrl} from '../auth/authSlice';

const Enrolment: React.FC = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const accessToken = useSelector((state: RootState) => state.auth.token);

    const {status, error} = useSelector((state: RootState) => state.enrolment);
    const location = useLocation();

    console.log("123")

    useEffect(() => {
        if (accessToken && isAuthenticated) {
            if (token && status === "idle") {
                dispatch(enrolUser({token, accessToken}));
            }
        } else {
            dispatch(setReturnUrl(location.pathname));
            navigate('/login')
        }
    }, [token, accessToken, isAuthenticated, dispatch, location.pathname, navigate, status]);

    useEffect(() => {
        if (status === 'succeeded') {
            navigate('/');
        } else if (status === 'failed') {
            navigate('/');
        }
    }, [status, navigate]);

    return (
        <div className="enrolment-container">
            <h2>Enrolling...</h2>
            {status === 'failed' && error && <p className="error">{error}</p>}
        </div>
    );
};

export default Enrolment;
