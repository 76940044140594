import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FieldState {
    value: string;
    isEditing: boolean;
}

interface EditableFieldsState {
    [key: string]: FieldState;
}

const initialState: EditableFieldsState = {};

const editableFieldsSlice = createSlice({
    name: 'editableFields',
    initialState,
    reducers: {
        setValue(state, action: PayloadAction<{ id: string; value: string }>) {
            const { id, value } = action.payload;
            if (state[id]) {
                state[id].value = value;
            }
        },
        setEditing(state, action: PayloadAction<{ id: string; isEditing: boolean }>) {
            const { id, isEditing } = action.payload;
            if (state[id]) {
                state[id].isEditing = isEditing;
            }
        },
        addField(state, action: PayloadAction<{ id: string; initialValue: string }>) {
            const { id, initialValue } = action.payload;
            if (!state[id]) {
                state[id] = { value: initialValue, isEditing: false };
            }
        },
        saveValue(state, action: PayloadAction<string>) {
            const id = action.payload;
            if (state[id]) {
                state[id].isEditing = false;
            }
        },
    },
});

export const { setValue, setEditing, addField, saveValue } = editableFieldsSlice.actions;
export default editableFieldsSlice.reducer;
