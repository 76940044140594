import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null;
    refreshToken: string | null;
    isAuthenticated: boolean;
    displayName: string | null;
    returnUrl: string | null;
    isTeacher: boolean;
    teacherMode: boolean;
}

const token = localStorage.getItem('jwtToken') || null;
const userDetails: UserDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

const initialState: AuthState = {
    token: token,
    refreshToken: localStorage.getItem('refreshToken') || null,
    isAuthenticated: !!token,
    displayName: userDetails.displayName,
    returnUrl: '/',
    isTeacher: userDetails.isTeacher,
    teacherMode: localStorage.getItem("teacherMode") === "1",
};

interface LoginResponse {
    token: string | undefined;
    refreshToken: string | undefined;
    displayName: string | undefined;
    isTeacher: boolean | undefined;
}

interface UserDetails {
    displayName: string | null;
    isTeacher: boolean;
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<LoginResponse>) => {
            state.token = action.payload?.token || null;
            state.isAuthenticated = !!state.token;
            state.displayName = action.payload.displayName || null;
            state.isTeacher = !!action.payload.isTeacher;
            state.isTeacher ? localStorage.setItem("teacherMode", "1") : localStorage.removeItem("teacherMode");
            state.teacherMode = state.isTeacher;
        },
        logout: (state) => {
            state.token = null;
            state.isAuthenticated = false;
            state.displayName = null;
            state.isTeacher = false;
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userDetails');
        },
        refreshTokens: (state) => {
            state.token = localStorage.getItem('jwtToken');
        },
        setReturnUrl(state, action: PayloadAction<string | null>) {
            state.returnUrl = action.payload;
        },
        toggleMode: (state) => {
            state.teacherMode = !state.teacherMode;
            localStorage.setItem("teacherMode", state.teacherMode ? "1" : "0");

        },
        setAuthTokens(state, action: PayloadAction<{ jwtToken: string, refreshToken: string }>) {
            state.token = action.payload.jwtToken;
            state.refreshToken = action.payload.refreshToken;
            state.isAuthenticated = !!state.token;
        },
    },
});

export const { loginSuccess, logout, refreshTokens, setAuthTokens, setReturnUrl, toggleMode } = authSlice.actions;
export default authSlice.reducer;
