import React from "react";
import {Route, Routes} from 'react-router-dom';
// import TeacherDashboard from './pages/TeacherDashboard';
import CourseList from './pages/CourseList';
import CourseDetails from './pages/CourseDetails';
import AssignmentEditor from "./pages/AssignmentEditor";

const TeacherRoutes: React.FC = () => {
    return (
        <Routes>
            {/*<Route path="/" element={<TeacherDashboard />} />*/}
            <Route path="/courses" element={<CourseList/>}/>
            <Route path="/" element={<CourseList/>}/>
            <Route path="/course/:id" element={<CourseDetails/>}/>
            <Route path="/course/:course/assignment/:id" element={<AssignmentEditor/>}/>
            {/* Add more teacher-specific routes here */}
        </Routes>
    );
};

export default TeacherRoutes;