import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {addError} from "./errorSlice";
import api from "../services/api";

// Define the initial state type
interface EnrolmentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

// Define the initial state
const initialState: EnrolmentState = {
    status: 'idle',
    error: null,
};

interface EnrolRequest {
    token: string;
    accessToken: string;
}

// Define an asynchronous thunk for enrolling
export const enrolUser = createAsyncThunk(
    'enrolment/enrollUser',
    async (request: EnrolRequest, thunkAPI) => {
        try {
            await api.post("/enrol", {"token": request.token});
            return true;
        } catch (error) {
            const errorMessage = 'Enrollment failed. Please check your token and try again.';
            thunkAPI.dispatch(addError(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// Create the slice
const enrolmentSlice = createSlice({
    name: 'enrolment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(enrolUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(enrolUser.fulfilled, (state) => {
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(enrolUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export default enrolmentSlice.reducer;
