import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import LoginForm from './features/auth/LoginForm';
import {RootState, AppDispatch} from './store';
import Header from "./components/header/Header";
import ErrorDisplay from "./components/ErrorDisplay";

import './App.css';
import {logout, refreshTokens} from "./features/auth/authSlice";
import eventEmitter from "./eventEmitter";
import StudentRoutes from "./features/studentMode/StudentRoutes";
import TeacherRoutes from "./features/teacherMode/TeacherRoutes";
import AuthCheck from "./features/auth/AuthCheck";
import Enrolment from "./features/studentMode/Enrolment";

const App: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const teacherMode = useSelector((state: RootState) => state.auth.teacherMode);


    useEffect(() => {
        const handleLogout = () => {
            dispatch(logout()); // Dispatch the logout action
        };

        eventEmitter.on('logout', handleLogout);

        return () => {
            eventEmitter.off('logout', handleLogout);
        };
    }, [dispatch]);

    useEffect(() => {
        const handleRefresh = () => {
            dispatch(refreshTokens()); // Dispatch the logout action
        };

        eventEmitter.on('refresh', handleRefresh);

        return () => {
            eventEmitter.off('refresh', handleRefresh);
        };
    }, [dispatch]);

    return (
        <Router>
            <Header/>
            <AuthCheck>
                <main className="main-content">
                    <ErrorDisplay/>
                    <Routes>
                        <Route
                            path="/login"
                            element={isAuthenticated ? <Navigate to="/"/> : <LoginForm/>}
                        />
                        <Route
                            path="/*"
                            element={!isAuthenticated ? <LoginForm/> : teacherMode ? <TeacherRoutes/> :
                                <StudentRoutes/>}
                        />
                        <Route path="/enrol" element={<Enrolment/>}/>
                    </Routes>
                </main>
            </AuthCheck>
        </Router>
    );
};

export default App;
