// CourseDetail.tsx
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import api from "../../../services/api";
import {t} from "i18next";
import MathJax from "../../../components/MathJax";

interface Course {
    id: number;
    title: string;
    description: string;
    modules: Module[]
}

interface Module {
    id: number;
    title: string;
    description: string;
    assignments: Assignment[];
}

interface Assignment {
    id: number;
    title: string;
    attempts?: any;
}

const AssignmentDisplay: React.FC<Assignment> = ({id, title}) => <div className={"assignment-title"}>
    <Link to={`/assignment/${id}`}>{title}</Link>
</div>;

const ModuleDisplay: React.FC<Module> = ({id, title, description, assignments}) =>
    <MathJax><div className={"module"}>
        <h3>{title}</h3>
        {description.split("\n\n").map(p => <p>{p}</p>)}
        {assignments.length > 0 && <h4>{t("assignments")}</h4>}
        {assignments.map(a => <AssignmentDisplay key={a.id} {...a}/>)}
    </div></MathJax>;

const CourseDetails: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [course, setCourse] = useState<Course | null>(null);

    useEffect(() => {
        const courseId: number = parseInt(id || "0");
        if (!courseId) {
            return;
        }
        api.post(`/courses/details`, {courseId})
            .then((response) => {
                setCourse(response.data);
            })
            .catch((error) => {
                console.error('Error fetching course details:', error);
            });
    }, [id]);
    console.log(id);
    console.log(id);

    if (!course) {
        return <p>{t("loading")}</p>
    }
    return (
        <div style={{padding: '20px'}}>
            <h1>{course.title}</h1>
            <p>{course.description}</p>
            <h2>{t('modules')}</h2>
            {course.modules.map(m => <ModuleDisplay key={m.id} {...m}/>)}
        </div>
    );
};

export default CourseDetails;
