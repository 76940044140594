import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import api from "../../../services/api";
import {t} from "i18next";

interface Course {
    id: number;
    title: string;
    desc: string;
}

const CourseList: React.FC = () => {
    const [courses, setCourses] = useState<Course[]>([]);

    useEffect(() => {
        api.get('/courses/list_enrolled')
            .then((response) => {
                setCourses(response.data.courses);
            })
            .catch((error) => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    return (<div style={{padding: '20px'}}>
        <h2>Welcome to the Student Dashboard</h2>
        {/* Rendering the list of courses */}
        <div>
            {courses.length === 0 ? (<p>{t('no_courses')}.</p>) : (courses.map((course) => (
                <div key={course.id} className={"course-box"}>
                    <Link to={`/course/${course.id}`}>
                        <h3 className={"course-box-title"}>{course.title}</h3>
                    </Link>
                    <p>{course.desc}</p>
                </div>)))}
        </div>
    </div>);
};

export default CourseList;
