import {t} from "i18next";
import React from "react";

interface ModalFormProps {
    save: () => void;
    preview: () => void;
    cancel: () => void;
    title: string;
    description: string;
    setTitle: (title: string) => void;
    setDescription: (title: string) => void;
}

const ModalForm = (props: ModalFormProps) => {
    const {save, preview, cancel, title, description, setTitle, setDescription} = props;
    return (<div className="modal">
            <div className="modal-content">
                <h2>{t('edit_course')}</h2>
                <label>
                    {t('title')}
                    <input
                        className={"modal-input"}
                        type="text"
                        value={title || ''}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </label>
                <label>
                    {t('description')}
                    <textarea
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        className={"modal-textarea"}
                    />
                </label>
                <div className="modal-actions">
                    <button className="button save-button" onClick={save}>
                        {t('save')}
                    </button>
                    <button className="button apply-button" onClick={preview}>
                        {t('preview')}
                    </button>
                    <button className="button cancel-button" onClick={cancel}>
                        {t('cancel')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ModalForm;