import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import OptionEditor from "./OptionEditor";
import {addOption, deleteVariant, updateVariant} from "../../../slices/assignmentSlice";
import {t} from "i18next";

import {ReactComponent as ApplyIcon} from "../../../svg/save.svg";
import {ReactComponent as EditIcon} from "../../../svg/pencil.svg";
import AutoResizeTextArea from "./AutoResizeTextArea";

interface VariantProps {
    questionId: number;
    variantId: number;
    questionType: string;
}

const VariantEditor: React.FC<VariantProps> = ({questionId, variantId, questionType}) => {
    const dispatch = useDispatch<AppDispatch>();

    const text = useSelector((state: RootState) => state.assignment.variants.find(v => v.id === variantId)?.text);
    const expected = useSelector((state: RootState) => state.assignment.variants.find(v => v.id === variantId)?.expected);
    const options = useSelector((state: RootState) => state.assignment.options.filter(v => v.variantId === variantId));

    const [isEditingText, setIsEditingText] = useState(false);
    const [isEditingExpected, setIsEditingExpected] = useState(false);

    useEffect(() => {
        setIsEditingText(false);
        setIsEditingExpected(false);
    }, [questionId, variantId, dispatch]);

    useEffect(() => {
        setIsEditingText(false);
        setIsEditingExpected(false);
    }, [variantId]);

    const showOptions = questionType === "single" || questionType === "multi";

    const OptionsEditor = () => {
        if (questionType === "file" || questionType === "free") return null; else if (questionType === "single" || questionType === "multi") return (
            <div className="options-section" key={"options-container"}>
                {options?.map((o, index) => (
                    <OptionEditor key={`o${o.id}`} {...{index, optionId: o.id, questionId, variantId}}/>))}
            </div>); else return null;
    }

    const ExpectedTextEditor = React.useMemo(() => {
        const expectedProps = {value: expected || "", placeholder: t("expected_text")};
        const expectedCallback = (s: string) => dispatch(updateVariant({variantId, expected: s}));

        return <div className="variant-text" key={"vee"}>
            <button className={"button"} onClick={() => setIsEditingExpected(!isEditingExpected)} key={"edit-btn"}>
                {isEditingExpected ? <ApplyIcon/> : <EditIcon/>}</button>
            {isEditingExpected ? <AutoResizeTextArea {...expectedProps} callback={expectedCallback}/> :
                <div className={"question-text"} key={`v${variantId}p`}>{expected || `(${t("expected_text")})`}</div>}
        </div>
    }, [variantId, isEditingExpected, expected, dispatch]);

    // ToDo: cleanup and make functional components
    const QuestionTextEditor = React.useMemo(() => {
        const textProps = {value: text || "", placeholder: t("question_text")};
        const textCallback = (s: string) => dispatch(updateVariant({variantId, text: s}));

        return <div className="variant-text" key={"vte"}>
            <button className={"button"} onClick={() => setIsEditingText(!isEditingText)} key={"edit-btn"}>
                {isEditingText ? <ApplyIcon/> : <EditIcon/>}</button>
            {isEditingText ? <AutoResizeTextArea {...textProps} callback={textCallback}/> :
                <div className={"question-text"} key={`v${variantId}p`}>{text || `(${t("question_text")})`}</div>}
        </div>
    }, [variantId, isEditingText, text, dispatch]);

    const AddOptionButton = <button className="add-option button"
                                    onClick={() => dispatch(addOption(variantId))}>{t("add_option")}</button>;

    const DeleteButton = <button className="button delete-button"
                                 onClick={() => dispatch(deleteVariant(variantId))}>
        {t("delete_variant")}
    </button>

    return (<div key={`v${variantId}`} className={"variant-editor"}>
        {QuestionTextEditor}
        {(questionType === "free") && ExpectedTextEditor}

        {/* Options */}
        <OptionsEditor key={`v${variantId}oe`}/>
        <div>
            {showOptions && AddOptionButton}
            {DeleteButton}
        </div>
    </div>);
}

export default VariantEditor;