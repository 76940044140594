import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {t} from "i18next";

import {AppDispatch, RootState} from "../../../store";
import {addVariant, deleteQuestion, updateQuestion} from '../../../slices/assignmentSlice';
import '../../../styles/questionEditor.css'
import VariantEditor from "./VariantEditor";

interface QuestionProps {
    questionId: number;
    index: number;
    type: string;
}

const QuestionEditor: React.FC<QuestionProps> = ({questionId, index, type}) => {
    const dispatch = useDispatch<AppDispatch>();

    const [activeVariantId, setActiveVariantId] = useState<number>(0);

    const variants = useSelector((state: RootState) => state.assignment.variants.filter(v => v.questionId === questionId), shallowEqual);
    const questionType = type;

    const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(updateQuestion({questionId, type: e.target.value}));
    };

    const toggleActiveVariant = (id: number) => {
        setActiveVariantId(id === activeVariantId ? -1 : id);
    }

    const handleAddVariant = () => {
        dispatch(addVariant(questionId));
    };

    const VariantTab = (variant: { id: number }, index: number) => (<button
        key={variant.id}
        className={"button" + (activeVariantId === variant.id ? " active" : "")}
        onClick={() => toggleActiveVariant(variant.id)}
    >
        {index + 1}
    </button>)

    const TypeSelector = <select className={"button"} value={questionType} onChange={handleTypeChange}>
        {<option value="single">{t("single_choice")}</option>}
        <option value="multi">{t("multiple_choice")}</option>
        <option value="free">{t("free_answer")}</option>
        <option value="file">{t("file_upload")}</option>
    </select>;

    const VariantTabs = <div className="variant-tabs" key="variant-tabs">
        <div className={"variants-hdr"}>{index + 1}. {TypeSelector} {t("variants")}</div>
        <div className={"variants-hdr"}>
            {variants.map((variant, index) => VariantTab(variant, index))}
            <button className="add-variant button" key="+" onClick={handleAddVariant}>+</button>
        </div>
    </div>;

    const activeVariant = variants.find(v => v.id === activeVariantId);

    return (<div className="question-editor">
        {VariantTabs}

        {/* Render the active variant */}
        {activeVariant && <VariantEditor variantId={activeVariant.id} {...{questionId, questionType}}/>}
        <div key={"buttons"}>
            <button className={"button delete-button"}
                    onClick={() => dispatch(deleteQuestion(questionId))}>{t("delete_question")}</button>
        </div>
    </div>);
};

export default QuestionEditor;
