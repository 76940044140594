import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorState {
    errors: string[]; // Array of errors
}

const initialState: ErrorState = {
    errors: [], // Initialize with an empty array
};

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        addError(state, action: PayloadAction<string>) {
            state.errors.push(action.payload); // Add new error to the array
        },
        removeError(state, action: PayloadAction<number>) {
            state.errors = state.errors.filter((_, index) => index !== action.payload); // Remove error at index
        },
        clearErrors(state) {
            state.errors = []; // Clear all errors
        },
    },
});

export const { addError, removeError, clearErrors } = errorSlice.actions;
export default errorSlice.reducer;
